import React from "react";
import CabinetLayout from "../components/cabinet/CabinetLayout";
import SEO from "../components/seo";
import EnsureLoginedView from "../components/cabinet/EnsureLoginedView";
import CabinetTopView from "../components/cabinet/CabinetTopView";
import SelectPackView from "../components/cabinet/SelectPackView";
import { L } from "../l10n/L10n";

const PacksPage = () => (
	<CabinetLayout>
		<SEO title={L.packsPage.title}
		     description={L.packsPage.desc}/>

		<EnsureLoginedView>
			<CabinetTopView title={L.packsPage.title}
			                desc={L.packsPage.desc}
			/>
			<SelectPackView/>
		</EnsureLoginedView>
	</CabinetLayout>
);

export default PacksPage;
