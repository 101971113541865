import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../App";
import { navigateTo, PagePath } from "../../helpers/PageHelper";
import EndSaleView from "../EndSaleView";
import { EStartSaleType } from "../StartSaleView";
import CabinetLayoutStyle from "./CabinetLayout.module.scss";
import PacksView from "./PacksView";

function getNoPacksView()
{
	return (
		<>
			<h3 className={CabinetLayoutStyle.textCenter}>You have no packs.</h3>
			<EndSaleView type={EStartSaleType.DEFAULT}/>
		</>
	);
}

const SelectPackView = observer(() => {
	const app = useApp();

	const loadData = async () => {
		await app.worldService.updatePackAndAsset();
		if (app.store.unpacking)
			await navigateTo(PagePath.UNPACKING);
	};

	useEffect(() => {
		loadData().catch();
	}, []);

	const packsData = app.assetConf.packs;
	const packViews = new Array<JSX.Element>();
	for (let i = 0; i < packsData.length; i++) {
		const packData = packsData[i];
		const userPacks = app.store.packs.querByName(packData.name);
		if (userPacks.length > 0) {
			const packView = <PacksView packData={packData}
			                            userPacks={userPacks}
			                            key={`pack-to-select-${packData.template_id}`}/>;
			packViews.push(packView);
		}
	}

	return (
		<div className={CabinetLayoutStyle.main_container}>
			{
				packViews.length > 0
					? packViews
					: getNoPacksView()
			}
		</div>
	);
});

export default SelectPackView;
