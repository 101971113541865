import { TUint64 } from "@pro/common/contracts/atomicassets";
import { eos, world } from "../../App";
import { EosActionBase } from "../EosActionBase";

export class TransferAction extends EosActionBase
{
	constructor(private readonly assetId: TUint64)
	{
		super("transfer");
	}

	async onExecute()
	{
		await eos.transfer(this.assetId, "unpack");
	}

	async onSelfUpdate(): Promise<void>
	{
		await world.mustUpdatePacks();
	}
}