import { IPackJson } from "@pro/common/conf";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useApp } from "../../App";
import { TransferAction } from "../../eos/actions/TransferAction";
import { navigateTo, PagePath } from "../../helpers/PageHelper";
import { cutLinkFromPackDesc } from "../../helpers/PackHelper";
import { L } from "../../l10n/L10n";
import { PackModel } from "../../stores/PackModel";
import Button from "../Button";
import Styles from "./PacksView.module.scss";

const PacksView = observer((
	{
		packData,
		userPacks,
	}: {
		packData: IPackJson,
		userPacks: PackModel[],
	}) =>
{
	const app = useApp();
	const amount = userPacks.length;

	const [btnReady, setBtnReady] = useState(true);

	const handleClick = async () => {
		const pack = userPacks[userPacks.length - 1];

		const action = new TransferAction(pack.assetId)
			.setReadyStateAction(setBtnReady);
		await action.execute();

		if (app.store.unpacking)
			await navigateTo(PagePath.UNPACKING);
	};

	return (
		<div className={Styles.content}>
			<img className={Styles.packImg} src={packData.img} alt="pack image"/>
			<div className={Styles.desc_container}>
				<div className={Styles.desc_content}>
					<h2>{packData.name}</h2>
					<p>{cutLinkFromPackDesc(packData.description)}</p>
					<div className={Styles.probability_content}>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.common}:</span>
								<strong>{packData.rarity_percent[0]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.rare}:</span>
								<strong>{packData.rarity_percent[1]}%</strong>
							</div>
						</div>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.unique}:</span>
								<strong>{packData.rarity_percent[2]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.epic}:</span>
								<strong>{packData.rarity_percent[3]}%</strong>
							</div>
						</div>
						<div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.legendary}:</span>
								<strong>{packData.rarity_percent[4]}%</strong>
							</div>
							<div className={Styles.probability_info}>
								<span>{L.rarity.mythic}:</span>
								<strong>{packData.rarity_percent[5]}%</strong>
							</div>
						</div>
					</div>

				</div>
				<div className={Styles.btn_container}>
					<div className={Styles.amount}>Amount: {amount}</div>
					<Button text="Unpack"
					        onClick={() => handleClick()}
					        disabled={amount < 1}
					        ready={btnReady}
					/>
				</div>
			</div>
		</div>
	);
});

export default PacksView;

